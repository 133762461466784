import { Link } from "@remix-run/react"

import { featuresConfig } from "@/config/features"
import type { PageConfig } from "@/config/pages"
import { cn } from "@/lib/utils/classnames"
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip"

import { useNavItemState } from "../../utils"

export const NavbarCollapsedItem = ({ page }: { page: PageConfig }) => {
	const { path, id, feature } = page
	const { isActive, pathWithState } = useNavItemState(path, id)

	const Icon = featuresConfig[feature].icon
	const navigationLabel = featuresConfig[feature].labels.navigation

	return (
		<Tooltip>
			<TooltipTrigger asChild>
				<Link
					to={pathWithState}
					className={cn(
						"inline-flex size-9 shrink-0 items-center justify-center rounded-md outline outline-0 outline-offset-1 outline-focus transition-colors focus-visible:outline-2",
						isActive ?
							"bg-primary text-primary-foreground"
						:	"hover:bg-hover/[8%] hover:text-foreground-strong",
					)}
				>
					<Icon className="size-5" />
				</Link>
			</TooltipTrigger>
			<TooltipContent side="right" sideOffset={8}>
				{navigationLabel}
			</TooltipContent>
		</Tooltip>
	)
}
