import { Link } from "@remix-run/react"
import { motion } from "framer-motion"

import { featuresConfig } from "@/config/features"
import type { PageConfig } from "@/config/pages"
import { cn } from "@/lib/utils/classnames"

import { useNavbar } from "../../context"
import { useNavItemState } from "../../utils"

export const NavbarExpandedItem = ({ page }: { page: PageConfig }) => {
	const { path, id, feature } = page

	const Icon = featuresConfig[feature].icon
	const navigationLabel = featuresConfig[feature].labels.navigation

	const { isActive, pathWithState } = useNavItemState(path, id)
	const { animations } = useNavbar()

	return (
		<motion.div {...animations.expandedItem}>
			<Link
				to={pathWithState}
				className={cn(
					"group ml-5 flex items-center space-x-2 rounded px-3 py-1.5 text-foreground/70 transition-colors",
					isActive ?
						"bg-primary font-medium text-primary-foreground"
					:	"hover:bg-hover hover:text-foreground-strong",
				)}
			>
				<Icon
					className={cn(
						"size-4",
						isActive ?
							"text-primary-foreground"
						:	"text-primary-text opacity-70 group-hover:text-foreground-strong group-hover:opacity-100 dark:text-foreground",
					)}
				/>
				<span>{navigationLabel}</span>
			</Link>
		</motion.div>
	)
}
